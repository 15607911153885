import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { ImageModule } from 'primeng/image';
import { TooltipModule } from 'primeng/tooltip';

import { EnvironmentInterface } from '@/shared/environment.interface';
import { ENVIRONMENT } from '@/shared/environment.token';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ImageModule, TooltipModule],
  selector: 'nvx-ui-avatar',
  standalone: true,
  templateUrl: './avatar.component.html',
})
export class AvatarComponent {
  environment: EnvironmentInterface = inject(ENVIRONMENT);
  firstName = input.required<string>();
  lastName = input<string | null | undefined>(undefined);
  profilePictureId = input<string | null | undefined>(undefined);
  showTooltip = input(false);

  initials = computed(() => {
    const firstName = this.firstName();
    const lastName = this.lastName();
    if (lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else {
      return firstName.charAt(0);
    }
  });

  fullName = computed(() => {
    return `${this.firstName()} ${this.lastName() ?? ''}`;
  });

  profilePictureURL = computed(() => {
    return this.profilePictureId()
      ? `${this.environment.eventSourceUrl}/files/download/${this.profilePictureId()}`
      : undefined;
  });
}
